
import { defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import AppService, {
  refreshSelectMedia,
  renderImageUrl,
  renderImageUrlEditor,
  showMultipleMedia,
  showSingleMedia,
} from "@/core/services/AppService";
import Editor from "@tinymce/tinymce-vue";
import { getTinymce } from "@tinymce/tinymce-vue/lib/cjs/main/ts/TinyMCE";
import { PostSearch, PostSearchDefault } from "@/store/model/PostSearch";
import { Actions } from "@/store/enums/StoreEnums";
import axios from "axios";
export default defineComponent({
  name: "edit_post_search",
  props: ["id"],
  components: { editor: Editor },

  setup(props) {
    const image_editor = ref();
    const image_news = ref();
    let x =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.getElementsByTagName("body")[0].clientWidth;
    let y =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.getElementsByTagName("body")[0].clientHeight;

    const editorConfig = {
      image_advtab: true,
      image_caption: true,
      image_description: true,
      image_uploadtab: true,
      height: 400,
      menubar: true,
      file_picker_callback: function (callback, value, meta) {
        let url = "/manager-media?type=editor";
        const instanceApi = getTinymce().activeEditor.windowManager.openUrl({
          url: url,
          title: "Quản lý media",
          width: x * 0.8,
          height: y * 0.95,
          onMessage: (api, message) => {
            //console.log("message", message, api);
          },
          onClose: async (instance, trigger) => {
            let dataImage = JSON.parse(image_editor.value);
            //console.log(dataImage);
            let image = renderImageUrlEditor(dataImage);
            let width = dataImage.width ? dataImage.width : "800";
            if (width > 1200) width = 1000;
            callback(image, {
              text: dataImage["path"],
              alt: dataImage["originalname"],
              width: width.toString(),
              height: "auto",
            });
          },
          onAction: function (instance, trigger) {
            // code for when "custom" buttons are triggered
            //console.log("instance", instance, trigger);
          },
        });
      },
      plugins: [
        "advlist autolink lists link charmap",
        "searchreplace visualblocks code fullscreen",
        "print anchor insertdatetime media image",
        "paste code wordcount table advtable",
      ],
      toolbar:
        "insertfile |formatselect | bold italic strikethrough underline |backcolor color | subscript superscript |  alignleft aligncenter alignright alignjustify | outdent indent anchor undo redo | lists  bullist numlist outdent indent | table |image|code|fullscreen",
    };
    const store = useStore();
    const router = useRouter();
    const formRef = ref<null | HTMLFormElement>(null);
    const loading = ref<boolean>(false);

    let objectData = ref<PostSearch>(
      JSON.parse(JSON.stringify(PostSearchDefault)) as PostSearch
    );
    const account_current = ref();

    const disabledDate = (time: Date) => {
      return time.getTime() < Date.now() - 86400000;
    };

    onMounted(async () => {
      setCurrentPageBreadcrumbs("Chỉnh sửa đại lý ", ["Đại lý"]);
      MenuComponent.reinitialization();
      await store.dispatch(Actions.GET_POST_SEARCH_DETAIL, props.id);
      account_current.value = store.getters["currentUser"];
      objectData.value = await store.getters["currentPostSearch"];
    });

    const rules = ref({
      title: [
        {
          required: true,
          message: "Tiêu đề  không được để trống",
          trigger: "blur",
        },
      ],
      description: [
        {
          required: true,
          message: "Mô tả không được để trống",
          trigger: "blur",
        },
      ],
    });
    const convertNameToSlug = (value) => {
      objectData.value["slug"] = AppService.convertSlug(value);
    };
    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid) => {
        if (valid) {
          store
            .dispatch(Actions.UPDATE_POST_SEARCH, objectData.value)
            .then(() => {
              Swal.fire({
                text: "Cập nhật thông  thành công",
                icon: "success",
                buttonsStyling: false,
                showCancelButton: true,
                confirmButtonText: "Danh sách",
                cancelButtonText: "Tiếp tục chỉnh sửa",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-success",
                  cancelButton: "btn fw-bold btn-light-primary",
                },
              }).then(async function (result) {
                if (result.isConfirmed) {
                  await router.push({ name: "posts-search" });
                } else {
                  let data = await store.getters["currentPostSearch"];
                  await router.push({
                    name: "posts-search-edit",
                    query: { id: objectData.value.link },
                  });
                }
              });
            })
            .catch(() => {
              Swal.fire({
                text: store.getters.getErrors,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Thử lại!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
            });
        } else {
          return false;
        }
      });
    };

    return {
      rules,
      submit,
      editorConfig,
      convertNameToSlug,
      renderImageUrl,
      showSingleMedia,
      showMultipleMedia,
      loading,
      objectData,
      formRef,
      image_editor,
      image_news,
    };
  },
  mounted() {
    //Event Listener for Iframe
    window.addEventListener("message", this.iframeEvent, false);
  },
  methods: {
    iframeEvent(event) {
      //Verify App Domain
      if (event.data.action == "editor") {
        this.image_editor = event.data.image;
        //console.log("data received editor: ", event.data);
      }
      if (event.data.action == "image-feature") {
        this.objectData["image"] = JSON.parse(event.data.image);
        this.image_news = JSON.parse(event.data.image);
        //console.log("image-feature: ", event.data.image);
      }
    },
  },
});
